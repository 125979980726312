import { gql, request} from 'graphql-request'


export const fetchPosts = async() => {
    const query = gql`
    query Posts {
      posts {
        createdAt
        date
        id
        keyIdea
        photo {
          url
        }
        publishedAt
        slug
        subtitle
        title
        updatedAt
        content {
          html
        }
      }
    }
      
      
    `;
    
    const result = await request('https://us-west-2.cdn.hygraph.com/content/clazmr0nm1vsv01uhcs2i1efj/master', query);
    return result
}
   

