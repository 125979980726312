import React from 'react'
import './about.css';
import SittingShot from '../../assets/cl-sitting-shot.png'

const About = () => {
  return (
    <div className="cl__about section__margin section__padding">
      <div className="cl__about-title">
        <h1>Some Things About Me</h1>
        <h2>Lifelong Learner</h2>
      </div>
      <div className="cl__about-content">
        <div className="cl__about-header">
          <h1>Here's a bit <span> about me:</span></h1>
          <div className="cl__about-facts">
            <h3>I'm a curious and easygoing student studying science and music composition at Stanford University. My work is rooted in a passion for helping others, realizing dreams, learning, and make the world a more fun place to live.</h3>
          </div>
          <h1>Here's some <span> fun facts:</span></h1>
          <div className="cl__about-facts">
            <h3><span></span>I really enjoy composing film scores (music soundtracks for movies). I started composing when I was 16 years old and since then, I've collaborated with many film directors and received national recognition for my work.</h3>
            <h3><span></span>I play bass and piano in a band called Claire's In Town.</h3>
            <h3><span></span>I was on the cross-country, basketball, and tennis teams in high school. These days, I'm obsessed with golf.</h3>
            <h3><span></span>I've been playing the tuba since I was 11. In 2019, I played on tour in Europe with my orchestra.</h3>
            <h3><span></span>I could eat apples, hawaiian pizza, and oreos all day long.</h3>
            <h3><span></span>My favorite movie is the social network - it's what inspired me to code! (plus it has an amazing soundtrack)</h3>
          </div>
          <h1>Here's some<span> skills:</span></h1>
          <p><i>* Denotes self-taught</i></p>
          <div className="cl__about-facts">
            <h3><span></span>Web Development and Programming (JavaScript*, HTML*, CSS*, Python, C++) </h3>
            <h3><span></span>Language (English, Mandarin)</h3>
            <h3><span></span>Music Performance (Piano, Tuba*, Trombone*, Bass*, and Guitar*)</h3>
            <h3><span></span>Music Composition (Film Scoring*, Logic Pro X*, Orchestration, Transcription*)</h3>
            <h3><span></span>Design (Adobe Photoshop* and Adobe Illustrator*)</h3>
          </div>
          <h1>Here's some <span> music awards:</span></h1>
          <div className="cl__about-facts">
            <h3><span></span>National Young Composers Challenge Finalist (2019, 2021)</h3>
            <h3><span></span>MTNA/CAPMT Honors State Finals: Honorable Mention (2021)</h3>
            <h3><span></span>Marin Music Chest: Senior Division Scholarship Winner, Piano (2021)</h3>
            <h3><span></span>Best Original Score in High School All American Film Festival (2020)</h3>
            <h3><span></span>Hugo Rinaldi Fellowship Award (2020)</h3>
            <h3><span></span>Bruce Raabe Junior Arts Fellowship (2020)</h3>
            <h3><span></span>Honorable Mention in International Indie Film Music Contest (2019)</h3>
          </div>
        </div>
        <div className="cl__about-table">
          <h1>Snapshot</h1>
          <table>
            <div className="cl__about-table-img">
              <img src={SittingShot}></img>
            </div>
            <tr>
              <th>Name:</th>
              <th>Education:</th>
              <th>Major:</th>
              <th>Minor:</th>
              <th>Hometown:</th>
              <th>Favorite Hobby:</th>
              <th>Favorite Song:</th>
            </tr>
            <tr>
              <td>Caleb Liu</td>
              <td>Stanford University, Class of 2025</td>
              <td>Computer Science</td>
              <td>Music Composition</td>
              <td>San Francisco, CA</td>
              <td>Golf</td>
              <td>When You Were Young (The Killers)</td>
            </tr>
          </table>
          <div className="cl__about-table-download-link">
            <a href="https://drive.google.com/uc?export=download&id=1rEUplQDUTY_F9vIrbdp0bVfyB7c_8Bw0">Download Resume</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About