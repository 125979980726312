import React, { useState } from 'react';
import './header.css';
import Typewriter from "typewriter-effect";
import Headshot from "../../assets/cl-headshot.png"

const Header = () => {
  return (
    <div className="cl__header section__padding section__margin">
        <div className="cl__header-image">
            <img src={Headshot}></img>
        </div>
        <div className="cl__header-heading">
            <h3>Welcome to my blog </h3>
            <h2>I'm <span className="name">Caleb</span></h2>
            <h1><Typewriter 
                        options={{
                            autoStart: true,
                            loop: true,
                            delay: 80,
                            strings: [
                                "Recreational Golf Player",
                                "Curious Student",
                                "Film Composer"
                            ],
                        }}
                    />
            </h1>
            <div className="cl__header-btn">
                <a href='/blog'>Read</a>
            </div>
        </div>
    </div>
  )
}

export default Header