import './App.css';
import { Navbar, Header, About, Projects, Blog, BlogPost} from './containers';
import  {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import HygraphContextProvider from './contexts/hygraphContext';
import { useEffect, useState } from 'react';
import { fetchPosts } from './hygraph';


function App() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getPosts() {
      const postsArr = await fetchPosts();
      setPosts(postsArr.posts)
    }
    getPosts()
  }, [])
  
  return (
    <HygraphContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<>
            <div className="App">
              <Navbar />
              <Header />
            </div>
          </>}/>
          <Route path="/about" element={<>
            <div className="page">
              <Navbar />
              <About />
            </div>
          </>}/>
          <Route path="/blog" element={<>
            <Navbar />
            <Blog />
          </>}/>
          {posts.map((post) => {
            return (
              <Route path = {"/blog/" + post.slug} element={<>
              <div>
                <Navbar/>
                <BlogPost
                  post={post}
                />
              </div></>}/>
            )
          })}
          <Route path="*" element={<>
            <Navbar />
          </>}/>
        </Routes>
      </Router>
    </HygraphContextProvider>
  );
}

export default App;
