import { createContext, useContext, useEffect, useState } from "react";
import { fetchPosts } from "../hygraph";

const HygraphContext = createContext({});

export const useHygraphContext = () => useContext(HygraphContext);

export function HygraphContextProvider({children}) {
    const [posts, setPosts] = useState(null);
    const [loading, setLoading] = useState(true);

    const getPosts = async() => {
      let posts = await fetchPosts();
      console.log(posts)
      setPosts(posts.posts);
    }
    
    useEffect(() => {
      getPosts();
    }, [])

    useEffect(() => {
      if (posts) {
        setLoading(false)
      }
    }, [posts])
    
    let value = {   
      posts,
      loading
    }

    return (
    <HygraphContext.Provider value={value}>
        {children}
    </HygraphContext.Provider>
    )
}

export default HygraphContextProvider;

