import React, { useState } from 'react';
import './navbar.css';
import BlackLogo from '../../assets/cl-logo.png'


const Navbar = () => {



    const[isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
        console.log(isActive);
    }
    
  return (
    <div className="cl__navbar">
        <a href="/"><img src={BlackLogo} alt="blacklogo"></img></a>
        <div className="cl__navbar-menu">
            <a href="/" class="is-active"><h1>Home</h1></a>
            <a href="/blog" ><h1>Blog</h1></a>
        </div>
        <div className="cl__navbar-hamburger">
            <checkbox onClick={toggleClass} id="cl__navbar-hamburger"className={isActive ? "is-active" : null}>
                <span></span>
                <span></span>
                <span></span>
            </checkbox>
            <div id="cl__navbar-overlay" className={isActive ? "is-active" : null}>
                <a className={isActive ? "is-active" : null} href="/" ><p>01</p>Home</a>
                <span className={isActive ? "is-active" : null} ></span>
                <a className={isActive ? "is-active" : null} href="/blog" ><p>02</p>Blog</a>
            </div>
        </div>
    </div>
  )
}

    

export default Navbar