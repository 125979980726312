import './blogPost.css'
import React from 'react'
import { useState } from 'react';

const BlogPost = (post) => {
    const [access, setAccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [password, setPassword] = useState("");
    const handleClick = () => {
        if (password.toUpperCase() == "jonathan18".toUpperCase()) {
            setAccess(true);
            setFail(false);
        } else {
            setFail(true);
        }
    }
    post = post.post;
    const parseDate = (date) => {
        let year = date.substring(0, 4);
        let month = date.substring(5, 7);
        let day = parseInt(date.substring(8, 10));
    
        // handle day
        if (day === 1 || day === 21 || day === 31) {
            day = day + "st"
        } else if (day === 2 || day === 22) {
            day = day + "nd"
        } else if (day === 3 || day === 23) {
            day = day + "rd"
        } else {
            day = day + "th"
        }
        // handle month
        if (month === "01") {
            month = "January"
        } else if (month === "02") {
            month = "Febuary"
        } else if (month === "03") {
            month = "March"
        } else if (month === "04") {
            month = "April"
        } else if (month === "05") {
            month = "May"
        } else if (month === "06") {
            month = "June"
        } else if (month === "07") {
            month = "July"
        } else if (month === "08") {
            month = "August"
        } else if (month === "09") {
            month = "September"
        } else if (month === "10") {
            month = "October"
        } else if (month === "11") {
            month = "November"
        } else if (month === "12") {
            month = "December"
        }
    
        return (month + " " + day + ", " + year)
    }

    const parseHygraphDate = (date) => {
        const year = date.substring(0, 4)
        const month = date.substring(5, 7)
        const day = date.substring(8, 10)
        let parsedDate = parseDate(year + "-" + month + "-" + day)
        return parsedDate
    }
  return (
    <>
    {access ? 
    <div className="section__margin section__padding flex__col cl__blog-post__container">
        <div className="flex__col cl__blog-post__post">
            <div className="flex__col cl__blog-post__header">
                <h1 className="cl__blog-post__post__title">{post.title}</h1>
                <h2 className="cl__blog-post__post__subtitle">{post.subtitle}</h2>
                <img className="claros__blog-post__cover-photo" src={post.photo.url} alt="coverPhoto"/>
                <h3 className="cl__blog-post__post__date">{parseHygraphDate(post.date)}</h3>
                <p>by: Caleb Liu</p>
            </div>
            <hr className="cl__blog-post__divider" />
            <div className="claros__article-text flex__col" dangerouslySetInnerHTML={{__html: post.content.html}} />
        </div>
    </div>
    : <div className="cl__blog-post__login flex__col section__margin section__padding">
        <div className="cl__blog-post__login__input flex__col">
            <p>Enter the secret key:</p>
            {fail && <h1>Wrong, try again.</h1>}
            <input onChange={e => setPassword(e.target.value)} placeholder="Secret Key" />
            <button onClick={() => handleClick()}>Gain Access</button>
        </div>
    </div>}</>

  )
}

export default BlogPost