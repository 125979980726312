import './blog.css'
import React from 'react'
import { useHygraphContext } from '../../contexts/hygraphContext'
import { useNavigate } from 'react-router-dom'

const Blog = () => {
    const {posts, loading} = useHygraphContext()
    const parseDate = (date) => {
        let year = date.substring(0, 4);
        let month = date.substring(5, 7);
        let day = parseInt(date.substring(8, 10));
    
        // handle day
        if (day === 1 || day === 21 || day === 31) {
            day = day + "st"
        } else if (day === 2 || day === 22) {
            day = day + "nd"
        } else if (day === 3 || day === 23) {
            day = day + "rd"
        } else {
            day = day + "th"
        }
        // handle month
        if (month === "01") {
            month = "January"
        } else if (month === "02") {
            month = "Febuary"
        } else if (month === "03") {
            month = "March"
        } else if (month === "04") {
            month = "April"
        } else if (month === "05") {
            month = "May"
        } else if (month === "06") {
            month = "June"
        } else if (month === "07") {
            month = "July"
        } else if (month === "08") {
            month = "August"
        } else if (month === "09") {
            month = "September"
        } else if (month === "10") {
            month = "October"
        } else if (month === "11") {
            month = "November"
        } else if (month === "12") {
            month = "December"
        }
    
        return (month + " " + day + ", " + year)
    }

    const parseHygraphDate = (date) => {
        const year = date.substring(0, 4)
        const month = date.substring(5, 7)
        const day = date.substring(8, 10)
        let parsedDate = parseDate(year + "-" + month + "-" + day)
        return parsedDate
    }
    const navigate = useNavigate()
  return (
    <>
    {!loading &&
    <div className="section__margin section__padding cl__blog">
        <h1 className="cl__blog__title">Content</h1>
        <div className="cl__blog__posts-map">
            {posts.map((post, i) => {
                return (
                    <div onClick={() => navigate(post.slug)} id={post.id} className="flex__col cl__blog__post">
                        <h3 className="cl__blog__post__date">{parseHygraphDate(post.date)}</h3>
                        <div className="cl__blog__post__header">
                            <h1 className="cl__blog__post__idx">{i + 1}</h1>
                        </div>
                        <h1 className="cl__blog__post__title">{post.title}</h1>
                        <img className="cl__blog__post__img" src={post.photo.url} alt="post" />
                    </div>
                )
            })}
        </div>
    </div>
    }</>
  )
}

export default Blog